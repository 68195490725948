import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthService } from '../../services';
import { isEmptyString, isNullOrUndefined } from '../../utils';
import { Button, Input, Select } from '../../components';
import styles from "./Register.module.css";

interface RegisterForm {
  name: string;
  surname: string;
  email: string;
  password: string;
  professionalProfile: string;
  termsConditionsPrivacyPolicyAcceptance: string;
}

interface RegisterProps {
  onRegister: () => void;
}

interface IProfessionalProfile {
  key: string;
  name: string;
}

export const Register = (props: RegisterProps, {authService = new AuthService()}) => {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState<RegisterForm>({
    name: '',
    surname: '',
    email: '',
    password: '',
    professionalProfile: '',
    termsConditionsPrivacyPolicyAcceptance: ''
  });
  const [professionalProfiles, setProfessionalProfiles] = useState<Array<IProfessionalProfile>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const loadProfessionalProfiles = async () => {
    setLoading(true);
    const response = await authService.professionalProfiles();
    const apiProfessionalProfiles : IProfessionalProfile[] = (response.data as string[])
      .map((x: string) => ({key: x, name: t("backend.professionalProfiles." + x)}));
    setProfessionalProfiles(apiProfessionalProfiles);
    setLoading(false);
  };

  useEffect(() => {
    loadProfessionalProfiles();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    setError(false);
    const { name, type, value } = event.target;

    let fieldValue: string = value;
    if (type === 'checkbox') {
      fieldValue = (event.target as HTMLInputElement).checked ? "true" : "";
    }

    const temp = { ...form, [name]: fieldValue };
    setForm(temp);

    let isFormValid = true;
    for (let key in temp) {
      if (temp.hasOwnProperty(key) && isEmptyString(temp[key as keyof RegisterForm])) {
        isFormValid = false;
      }
    }
    setIsFormValid(isFormValid);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await authService.register(form.email, form.password, form.name, form.surname, form.professionalProfile, i18n.language);
    const success = response.success && !isNullOrUndefined(response.data) && (response.data as boolean);
    if (success) {
      props.onRegister();
    } else {
      setError(true);
    }
  };

  return (
    <div className={styles["register"]}>
      <h2>{t("createYourAccount")}</h2>
      <form onSubmit={handleSubmit} className={styles["registerForm"]}>
        <div>
          <Input type="text" name="name" placeholder={t("name").toString()} value={form.name} onChange={handleInputChange} />

          <Input type="text" name="surname" placeholder={t("surname").toString()} value={form.surname} onChange={handleInputChange} />
        </div>
        <div>
          <Input type="email" name="email" placeholder={t("email").toString()} value={form.email} onChange={handleInputChange} />

          <Input type="password" name="password" placeholder={t("password").toString()} value={form.password} onChange={handleInputChange} />
        </div>
        <div>
          <Select name="professionalProfile" id="professionalProfile" onChange={handleInputChange} defaultValue={""}>
            <option value="" disabled hidden>{t("professionalProfile")}</option>
            {professionalProfiles.map((professionalProfile) => 
              <option key={professionalProfile.key} value={professionalProfile.key}>{professionalProfile.name}</option>
            )}
          </Select>
        </div>
        <div className={styles["registerInfos"]}>
          <Input type="checkbox" id="termsConditionsPrivacyPolicyAcceptance" name="termsConditionsPrivacyPolicyAcceptance" onChange={handleInputChange} />
          <label htmlFor="termsConditionsPrivacyPolicyAcceptance">
            <p>{t("registerPrivacyPolicyTermsConditionsAcceptance.part1")}
              <Link to="https://vetsmate.app/?page_id=132" target="_blank">{t("registerPrivacyPolicyTermsConditionsAcceptance.termsAndConditions")}</Link>
              {t("registerPrivacyPolicyTermsConditionsAcceptance.part2")}
              <Link to="https://vetsmate.app/?page_id=3" target="_blank">{t("registerPrivacyPolicyTermsConditionsAcceptance.privacyPolicy")}</Link>
            </p>
          </label>
        </div>
        <div className={styles["registerButton"]}>
          <Button type="submit" disabled={loading || !isFormValid}>{t("createAccount")}</Button>
        </div>
        {error && <div>{t("registerInvalidData")}</div>}
      </form>
      <p className={styles["registerInfos"]}>
        {t("alreadyHaveAnAccount") + " "} <Link to="/login">{t("login")}</Link>
      </p>
    </div>
  );
};
